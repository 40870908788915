import {initializeApp, FirebaseOptions} from "firebase/app";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {Logger} from "platform/logger/Logger";
import {FirebaseAuthLogin} from "signup/core/type/FirebaseAuthLogin";
import {Configuration} from "signup/core/config/Configuration";

export class FirebaseAuth {

    private static _logger: Logger = Logger.Of("FirebaseAuth");
    private static _auth;
    private static _initialized: boolean;

    public static init = (brandId: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (this._initialized) {
                resolve();
            }
            const {Firebase} = Configuration.Get();
            const firebaseConfig: FirebaseOptions = Firebase[brandId.toString()];
            if (firebaseConfig) {
                const app = initializeApp(firebaseConfig);
                this._auth = getAuth(app);
                this._initialized = true;
                resolve();
            } else {
                this._logger.warn(`Empty Firebase config for brandId ${brandId}`);
                reject(new Error(`Firebase configuration for brandId ${brandId} missed.`));
            }
        });
    }

    public static Login = ({username, password, brandId}: FirebaseAuthLogin): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            this.init(brandId)
                .then(()=>{
                if (username && password) {
                    this._logger.info(`Try to SignIn with: ${username}`);
                    signInWithEmailAndPassword(this._auth, username, password)
                        .then(async (userCredential) => {
                            const token = await userCredential.user.getIdToken(true);
                            if (token) {
                                resolve(token);
                            } else {
                                this._logger.warn("Firebase token failed");
                                reject({errorMessage: "Firebase token failed"});
                            }
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            this._logger.warn(`SignIn failed with errorCode: ${errorCode}. ${errorMessage}`);
                            reject({errorCode, errorMessage});
                        });
                } else {
                    this._logger.debug("User / pass empty.");
                    reject("User / pass empty.")
                }
            }).catch(err => reject(err));
        });
    }
}
